<template>
  <div>
    <el-submenu v-if="item.children.length" ref="subMenu" :a="item.path" :index="item.path" :popper-append-to-body="false">
      <span slot="title">{{ item.name }}</span>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        class="nest-menu"
      />
    </el-submenu>
    <template v-else>
      <el-menu-item :index="item.path" :a="item.path">
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>

export default {
  name: 'SidebarItem',
  props: {
    // route object
    item: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {}
  },
  created() {
  },
  methods: {
  }
}
</script>
