import { getToken } from '@/api/user.js'
import environment from '@/environment/index.js'
import Cookies from 'js-cookie'

const state = {
  cookie: Cookies.get('userstatus'),
  token: '',
  survey_token: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    console.log(token, 'token')
    Cookies.set('token', token, { expires: 1 })
    state.token = token
  },
  REMOVE_TOKEN: (state, token) => {
    Cookies.remove('token')
    state.token = ''
  },
  SET_SURVEY_TOKEN: (state, token) => {
    sessionStorage.setItem('survey_token', token)
    state.survey_token = token
  },
  REMOVE_SURVEY_TOKEN() {
    sessionStorage.removeItem('survey_token')
    state.survey_token = ''
  }
}

const actions = {
  async getToken({ commit, state }, mixauthid) {
    console.log(state, '---')
    const parms = {
      type: 'scrm',
      user_token: state.cookie,
      wx_id: '',
      auth_id: mixauthid || '980636'
    }
    try {
      const data = await getToken(parms)
      console.log(Number(data.code) === 0)
      if (Number(data.code) === 0) {
        commit('SET_TOKEN', data.data.token)
      } else {
        // not token to login
        window.location.href = environment.login_url
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
