<template>
  <div class="app-wrapper">
    <!-- <page-header /> -->
    <el-container>
      <!-- <el-aside width="210px"> <sidebar class="sidebar-container" /></el-aside> -->
      <el-main class="main-container">
        <app-main />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { AppMain, Sidebar, PageHeader } from './components'

export default {
  name: 'Layout',
  components: {
    // PageHeader,
    AppMain
    // Sidebar
  }
}
</script>

<style lang="scss" scoped>

</style>
