
const hostname = window.location.hostname
const baseEnv = ['cloud', 'stg', 'stress', 'fw', 'fe.ma', 'cd01']
let curEnv = 'cloud'
baseEnv.filter(item => {
  if (hostname.includes(item)) {
    curEnv = item
  }
})
console.log(curEnv)
let environment = {
  api_url: `https://api-${curEnv}.ma.scrmtech.com`,
  app_url: `https://app-${curEnv}.ma.scrmtech.com`,
  upload_url: `https://up-1-${curEnv}.ma.scrmtech.com`,
  domain: `https://fe-${curEnv}.ma.scrmtech.com`,
  system_domain: `https://${curEnv}.ma.scrmtech.com`,
  no_auth_url: `https://${curEnv}.ma.scrmtech.com/Index/defaultContent`,
  set_souce: `https://leads-${curEnv}.ma.scrmtech.com/leads/website/source-setting/index`,
  login_url: `https://${curEnv}.ma.scrmtech.com/user/index/login`
}
if (curEnv === 'fw') {
  environment = {
    api_url: `https://${curEnv}.ma.scrmtech.com/api`,
    app_url: `https://${curEnv}.ma.scrmtech.com/app`,
    upload_url: `https://${curEnv}.ma.scrmtech.com/go/up-1`,
    domain: `https://fe-${curEnv}.ma.scrmtech.com`,
    system_domain: `https://${curEnv}.ma.scrmtech.com`,
    no_auth_url: `https://${curEnv}.ma.scrmtech.com/Index/defaultContent`,
    set_souce: `https://${curEnv}.ma.scrmtech.com/leads/leads/website/source-setting/index`,
    login_url: `https://${curEnv}.ma.scrmtech.com/user/index/login`
  }
}
if (curEnv === 'fe.ma') {
  environment = {
    api_url: `https://api.ma.scrmtech.com`,
    app_url: `https://app.ma.scrmtech.com`,
    upload_url: `https://up-1.ma.scrmtech.com`,
    domain: `https://fe.ma.scrmtech.com`,
    system_domain: `https://ma.scrmtech.com`,
    no_auth_url: `https://ma.scrmtech.com/Index/defaultContent`,
    set_souce: `https://leads.scrmtech.com/leads/website/source-setting/index`,
    login_url: `https://ma.scrmtech.com/user/index/login`
  }
}
if (process.env.NODE_ENV === 'development') {
  for (const key in environment) {
    environment[key] = ''
  }
}
export default environment
