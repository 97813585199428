import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import Cookies from 'js-cookie'
Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules,
  getters
})
if (Cookies.get('token')) {
  store.commit('user/SET_TOKEN', Cookies.get('token'))
}
if (sessionStorage.getItem('survey_token')) {
  store.commit('user/SET_SURVEY_TOKEN', sessionStorage.getItem('survey_token'))
}
if (Cookies.get('list_url')) {
  store.commit('list/SET_LIST_URL', Cookies.get('list_url'))
}
if (Cookies.get('detail_url')) {
  store.commit('list/SET_DETAIL_URL', Cookies.get('detail_url'))
}

export default store
