import Vue from 'vue'
import ElementUI from 'element-ui'
import router from './router'
import store from './store'
import App from './App.vue'

import '@/styles/reset.scss' // a modern alternative to CSS reset
import 'element-ui/lib/theme-chalk/index.css'
import './styles/element-variables.scss'
import '@/styles/index.scss' // global css

Vue.config.productionTip = false

Vue.use(ElementUI, {
  size: 'small' // set element-ui default size
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

/**
 * renew 菜单重复点击  刷新 scrm 页面
 */
window.addEventListener('message', function(e) {
  if (e.data.type === 'RENEW' && e.data.url) {
    window.location.href = e.data.url
  }
})
