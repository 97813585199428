/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout'

const surveyQuestionnairePageRouter = {
  path: '/',
  component: Layout,
  redirect: '/quest/list',
  children: [
    {
      path: 'list',
      component: () => import('@/views/questionnaire/questionnaire_list/index.vue'),
      name: 'list',
      meta: { title: '问卷列表' }
    },
    {
      path: 'detail',
      component: () => import('@/views/questionnaire/detailPage/index.vue'),
      name: 'detail',
      meta: { title: '问卷详情' }
    },
    {
      path: 'addEdit',
      component: () => import('@/views/questionnaire/addEdit/index.vue'),
      name: 'addEdit',
      meta: { title: '创建问卷' }
    },
    {
      path: 'export-record',
      component: () => import('@/views/questionnaire/exportRecord/index.vue'),
      name: 'exportRecord',
      meta: { title: '导出记录' }
    },
    {
      path: 'exportSet',
      component: () => import('@/views/questionnaire/exportSet/index.vue'),
      name: 'exportSet',
      meta: { title: '' }
    },
    {
      path: 'answer_sheet',
      component: () => import('@/views/questionnaire/answer_sheet/index.vue'),
      name: 'answerSheet',
      meta: { title: '' }
    },
    {
      path: 'bsd_edit',
      component: () => import('@/views/questionnaire/bsd_edit/index.vue'),
      name: 'bsd_edit',
      meta: { title: '答卷' }
    }

  ]
}

export default surveyQuestionnairePageRouter
