import Cookies from 'js-cookie'
const state = {
  list_url: '',
  detail_url: ''
}
const mutations = {
  SET_LIST_URL: (state, payload) => {
    Cookies.set('list_url', payload)
    state.list_url = payload
  },
  SET_DETAIL_URL: (state, payload) => {
    Cookies.set('detail_url', payload)
    state.detail_url = payload
  }
}
export default {
  namespaced: true,
  state,
  mutations

}
