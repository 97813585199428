import axios from 'axios'
import store from '@/store'
import { getQueryVariable } from '@/utils/index.js'
import environment from '../environment'
// import qs from 'qs'
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: '/',
  withCredentials: true // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
})
service.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// service.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
// request interceptor
console.log(process.env.NODE_ENV, 'process.env.NODE_ENV ')
if (process.env.NODE_ENV === 'development') {
  document.cookie = 'userstatus=6FkgST%2Be%2FaIMzxG2tMPT%2FsZb3UNEnfmt4Q'
}

service.interceptors.request.use(
  config => {
    if (config.method === 'get') {
      config.headers['Accept'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }
    config.url = decodeURI(encodeURI(config.url).replace(/%E2%80%8B/g, ''))
    if (process.env.NODE_ENV === 'development') {
      if (window.location.href.includes('answer_sheet')) {
        store.getters.survey_token && (config.headers['token'] = store.getters.survey_token)
      } else {
        store.getters.token && (config.headers['token'] = store.getters.token)
      }
    } else {
      if (!config.url.includes(environment.app_url) && !config.url.includes(environment.upload_url)) {
        if (window.location.href.includes('answer_sheet')) {
          store.getters.survey_token && (config.headers['token'] = store.getters.survey_token)
        } else {
          store.getters.token && (config.headers['token'] = store.getters.token)
        }
      }
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  async response => {
    const res = response.data
    if (response.status === 200) {
      switch (res.code) {
        case 104:
          window.location.href = `${environment.domain}/quest/no_auth?wx_id=${getQueryVariable('wx_id')}&mixauthid=${getQueryVariable('mixauthid')}`
          return
        case 103:
          await store.dispatch('user/getToken', getQueryVariable('mixauthid'))
          window.onload()
          return
      }
      return res
    } else {
      return Promise.reject(new Error(res.message || 'Error'))
    }
  },
  error => {
    console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
