import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Cookies from 'js-cookie'
Vue.use(Router)

/* Router Modules */
import questionnaire from './modules/questionnaire'

export const constantRoutes = [
  {
    path: '/',
    redirect: '/list'
  },
  questionnaire,
  {
    path: '/no_auth',
    component: () => import('@/views/error_page/no_auth.vue'),
    name: 'no_auth',
    meta: { title: '无权限' }
  }
]
const createRouter = () => new Router({
  mode: 'history', // require service support
  base: '/quest',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

// 对比是否重新登录过  如果重新登陆需要清除token

const userstatus = Cookies.get('userstatus')

const router = createRouter()

router.beforeEach(async(to, from, next) => {
  const survey_userstatus = Cookies.get('survey_userstatus')
  window.document.title = to.meta.title === undefined ? '调查问卷' : to.meta.title
  if (to.name === 'exportSet') {
    to.meta.title = to.query.survey_type === '2' ? '倍市得问卷导出筛选' : '问卷导出筛选'
    document.title = to.meta.title
  }
  if (!store.state.user.token && to.path !== '/answer_sheet') {
    await store.dispatch('user/getToken', to.query.mixauthid)
    Cookies.set('survey_userstatus', userstatus)
    next()
  } else {
    if (decodeURIComponent(survey_userstatus) !== decodeURIComponent(userstatus) && to.path !== '/answer_sheet') {
      await store.dispatch('user/getToken', to.query.mixauthid)
      Cookies.set('survey_userstatus', userstatus)
      next()
    } else {
      next()
    }
  }
})
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
