<template>
  <div>
    <!-- <logo v-if="showLogo" :collapse="isCollapse" /> -->
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        :router="true"
      >
        <sidebar-item v-for="route in permission_routes" :key="route.path" :item="route" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
// import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarItem },
  data() {
    return {
      permission_routes: [
        {
          id: 1,
          name: '社交营销',
          parentId: 0,
          menuUrl: '/',
          path: '/questionnaire'
        },
        // {
        //   id: 2,
        //   name: '营销素材',
        //   parentId: 1,
        //   menuUrl: '',
        //   pluginId: 1001,
        //   path: '/spreadListPage/material'
        // },
        {
          id: 17,
          name: '调查问卷',
          parentId: 1,
          menuUrl: '',
          pluginId: 1001,
          path: '/questionnaire/list'
        },
        {
          id: 19,
          name: '调查问卷详情',
          parentId: 1,
          menuUrl: '',
          pluginId: 1001,
          path: '/questionnaire/detail'
        },
        {
          id: 18,
          name: '添加',
          parentId: 1,
          menuUrl: '',
          pluginId: 1001,
          path: '/questionnaire/addEdit'
        },
        {
          id: 20,
          name: '导出记录',
          parentId: 1,
          menuUrl: '',
          pluginId: 1001,
          path: '/questionnaire/export-record'
        },
        {
          id: 21,
          name: '导出筛选',
          parentId: 1,
          menuUrl: '',
          pluginId: 1001,
          path: '/questionnaire/exportSet'
        }
      ]
    }
  },
  computed: {
    // ...mapGetters(['permission_routes']),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return false
    }
  },
  created() {
    this.filterMenuListData(this.permission_routes)
  },
  methods: {
    filterMenuListData(source) {
      const cloneData = source
      const result = cloneData.filter(father => {
        const branchArr = cloneData.filter(child => father.id == child.parentId)
        branchArr.length > 0 ? (father.children = branchArr) : (father.children = [])
        return father.parentId == 0
      })
      console.log(result)
      this.permission_routes = result
    }
  }
}
</script>
